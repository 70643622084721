.appContainer {
  margin-top: 3.5rem;
}

@media screen and (max-width: 767px) {
  .picture {
    max-width: 40%;
  }

  .bibliography {
    padding: 1rem;
  }
}
