.experienceContainer {
  text-align: center;
  align-items: center;
  height: 90vh;
  height: 90%;
  width: 100%;
  background-color: rgba(255, 241, 220, 0.2);
  padding: 0.5rem;
}

.homeContainer {
  padding: 10rem;
  height: 100vh;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 241, 220, 0.2);
}

.homeRow {
  justify-content: center;
  /* background-color: #f7e5d4; */
}

.contactContainer {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20vh;
  background-color: rgba(255, 241, 220, 0.2);
  padding: 0.5rem;
}

.linkedinLink {
  color: black;
  text-decoration: none;
}

.contactTitle {
 padding: 1rem;
}
