.pictureContainer {
  justify-content: center;
  text-align: center;
}

.picture {
  max-width: 80%;
  max-height: 80%;
}

.companyPage {
  text-decoration: none;
  font-weight: bold;
  color: #00463d !important;
}

.text {
  font-family: Montserrat;
  font-weight: 900;
  padding: 0.5rem;
}
