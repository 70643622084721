.red {
  background-color: red;
}

.bibliographyHeader {
  padding: 5;
  margin-top: 5;
  font-family: Montserrat;
  font-weight: 900;
}

.bibliographyHeader2 {
  padding: 5;
  margin-top: 5;
  font-family: Montserrat;
  font-weight: 900;
}

.bibliographyText {
  padding: 5;
  margin-top: 5;
  font-family: Montserrat;
}

